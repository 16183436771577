<template>
  <v-card
    flat
    class="pa-3 mt-1"
  >
    <v-card-title class="d-flex align-center justify-center py-0">
      <div
        class="d-flex align-center"
      >
        <v-img
          :src="require(`@/assets/images/logos/logo.png`)"
          max-width="300px"
          alt="logo"
          contain
          class="me-0 "
        ></v-img>
      </div>
    </v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Create a Loan</p>

        <v-row>
          <v-col cols="10" class="py-0 my-1">
            <v-text-field
              v-model="userName"
              ref="userName"
              outlined dense
              label="Search username, phone number, unique code..."
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="py-0 my-1">
            <v-btn
              color="success"
              class="me-3"
              fab small
              @click="getUsers()"
            >
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedUser"
              ref="selectedUser"
              :items="users"
              item-text="username"
              item-value="id"
              outlined dense
              label="Select a user"
            ></v-select>
          </v-col>
          <v-col
            cols="6" xs="6"
            class="py-0 my-1"
          >
            <v-text-field class="pa-0 mt-3"
              ref="loan_name" v-model="loanName"
              label="Enter Loan Name"
              :rules="[v => !!v || 'Loan Name is required']"
              color="primary"
            ></v-text-field>
          </v-col>
          <v-col
            cols="6" xs="6"
            class="py-0 my-1"
          >
            <v-text-field class="pa-0 mt-3"
              ref="loan_interest" v-model="loanInterest"
              label="Enter Loan Interest"
              :rules="[v => !!v || 'Loan Interest is required']"
              color="primary"
            ></v-text-field>
          </v-col>
          <v-col
            cols="6" xs="6"
            class="py-0 my-1"
          >
            <v-select
              @change="OnChangeLoanType()"
              class="pt-0 mt-3" color="primary"
              v-model="loanType" ref="loan_type"
              :items="loanTypes"
              :rules="[v => !!v || 'Loan Types is required']"
              label="Select Loan Types"
              required
            ></v-select>
          </v-col>
          <v-col
            cols="6" xs="6"
            class="py-0 my-1"
          >
            <v-text-field class="pa-0 mt-3"
              ref="loan_target_amount"
              label="Loan Amount" v-model="loanTargetAmount"
              :disabled='isAmountDisabled'  :required='isAmountRequired'
              color="primary"
            ></v-text-field>
          </v-col>
          <v-col
            cols="6" xs="6"
            class="py-0 my-1"
          >
            <v-text-field class="pa-0 mt-3"
              ref="loan_target_date"
              label="Loan Date" v-model="loanTargetDate"
              :disabled='isDateDisabled' :required='isDateRequired'
              type="date"
              color="primary"
            ></v-text-field>
          </v-col>
          <v-col
            cols="6"
            class="py-0 my-1"
          >
            <v-textarea class="pa-0 mt-3"
              name="input-7-1" outlined
              auto-grow  v-model="loanDescription"
              ref="loan_description"
              label="Loan Description"
              :rules="[v => !!v || 'Loan Description is required']"
              background-color="grey lighten-4"  color="primary"
              rows="2" 
              row-height="20" 
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text-end">
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn 
          v-else
          color="primary"
          class="me-3 mt-3"
          @click="createLoan()"
        >
          Create Loan
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'
// eslint-disable-next-line object-curly-newline
import Spinner from "@/components/Spinner.vue";


export default {
  components: {
    Spinner,
  },

  data() {
    return {
      loading: false,
      userName: '',
      selectedUser: '',
      users: [],
      isAmountDisabled: false,
      isDateDisabled: false,
      isAmountRequired: false,
      isDateRequired: false,
      loan_name_help: false,
      loan_type_help: false,
      loan_amount_help: false,
      loan_date_help: false,
      loan_description_help: false,
      loanName: "",
      loanInterest: "",
      loanType: "",
      loanTargetAmount: "",
      loanTargetDate: "",
      loanDescription: "",
      loanTypes: [],
    }
  },
  mounted(){
    this.getLoanTypes();
    document.title = "Manager: Savings | Create Loan"
  },

  methods:{
    getUsers(){
      axios
        .get('/api/v1/manager/get/clients/'+this.userName+'/')
        .then(response => {
          this.users = response.data  // get the data and fill into loans
          console.log(this.users)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async getLoanTypes(){
      this.loading = true
      
      await axios
        .get('/api/v1/manager/get/loan/types/')
        .then(response => {
            this.loanTypes = response.data  // get the data and fill into loans
        })
        .catch(error => {
            if (error.response){
                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
            }else{
                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
            }
        })

      this.loading = false
    },

    async createLoan(){
        var future = new Date();
        future.setDate(future.getDate() + 30);  // sets the future to 30 days from now
        console.log(future)
        
        if(this.loanName === ""){
            this.$store.commit('setSnackBarMessage', "Please fill in the loan name")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.loan_name.focus()  // this causes a an auto fucos to the element
        }
        if(this.loanInterest === ""){
            this.$store.commit('setSnackBarMessage', "Please fill in the loan interest")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.loan_interest.focus()  // this causes a an auto fucos to the element
        }
        else if(!this.selectedUser){
            this.$store.commit('setSnackBarMessage', "Please select a user")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.selectedUser.focus()  // this causes a an auto fucos to the element
        }
        else if(this.loanType === ""){
            this.$store.commit('setSnackBarMessage', "Please select the loan type")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.loan_type.focus()  // this causes a an auto fucos to the element
        }
        else if ((this.loanType === "MONEY_TARGET" || this.loanType === "MONEY_TIME_TARGET") && Number(this.loanTargetAmount) < 5000){
            this.$store.commit('setSnackBarMessage', "Please your loan target amount should be from 5000 F CFA and above.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.loan_target_amount.focus()  // this causes a an auto fucos to the element
        }
        else if ((this.loanType === "TIME_TARGET" || this.loanType === "MONEY_TIME_TARGET") && new Date(this.loanTargetDate) < future){
            this.$store.commit('setSnackBarMessage', "Sorry your loan date must be atleast <b>1 month from now</b>.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.loan_target_date.focus()  // this causes a an auto fucos to the element
        }
        else if(this.loanType === "MONEY_TARGET" && this.loanTargetAmount === ""){
            this.$store.commit('setSnackBarMessage', "Please input your loan target amount")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.loan_target_amount.focus()  // this causes a an auto fucos to the element
        }
        else if(this.loanType === "TIME_TARGET" && this.loanTargetDate === ""){
            this.$store.commit('setSnackBarMessage', "Please select your loan target date")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.loan_target_date.focus()  // this causes a an auto fucos to the element
        }
        else if(this.loanType === "MONEY_TIME_TARGET" && this.loanTargetDate === ""){
            this.$store.commit('setSnackBarMessage', "Please select your loan target date")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.loan_target_date.focus()  // this causes a an auto fucos to the element
        }
        else if(this.loanType === "MONEY_TIME_TARGET" && this.loanTargetAmount === ""){
            this.$store.commit('setSnackBarMessage', "Please input your loan target amount")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.loan_target_amount.focus()  // this causes a an auto fucos to the element
        }
        else if(this.loanDescription === ""){
            this.$store.commit('setSnackBarMessage', "Please input your loan description")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.loan_description.focus()  // this causes a an auto fucos to the element
        }
        else {
            this.loading = true
            let formData = {
              'user_id': this.selectedUser, 
              'loanName': this.loanName, 
              'loanInterest': this.loanInterest, 
              'loanType': this.loanType, 
              'loanTargetAmount': this.loanTargetAmount, 
              'loanTargetDate': this.loanTargetDate, 
              'loanDescription': this.loanDescription
            }

            await axios
                .post('/api/v1/manager/create/new_loan/', formData)
                .then(response => {
                    this.$store.commit('setSnackBarMessage', response.data[0].text)
                    this.$store.commit('setSnackBarColor', response.data[0].color)
                    this.$store.commit('activateSnackBar', true)
                    if(response.data[0].id !== 0){
                        let router = this.$router
                        setTimeout(function(){
                            router.push('/savings/clients/loans/')
                        }, 4000)
                    }
                })
                .catch(error => {
                    if (error.response){
                        this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }else{
                        this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }
                })
            this.loading = false
        }
    },

    OnChangeLoanType(){
        console.log(this.loanType)
        if(this.loanType === "MONEY_TARGET"){
            this.isAmountDisabled = false
            this.isDateDisabled = true                    
            this.isAmountRequired = false
            this.isDateRequired = true                    
        }
        else if(this.loanType === "TIME_TARGET"){
            this.isAmountDisabled = true
            this.isDateDisabled = false                                        
            this.isAmountRequired = true
            this.isDateRequired = false                                        
        }
        else if(this.loanType === "MONEY_TIME_TARGET"){
            this.isAmountDisabled = false
            this.isDateDisabled = false                                        
            this.isAmountRequired = false
            this.isDateRequired = false                                        
        }
        else if(this.loanType === "UNDEFINED TARGET"){
            this.isAmountDisabled = true
            this.isDateDisabled = true                                        
            this.isAmountRequired = true
            this.isDateRequired = true                                        
        }
    },

  },
}
</script>
